function getNestedObject(flatObject) {
  const uuidRex = /^[a-z,0-9,-]{36,36}_/;
  return Object.entries(flatObject) 
    .reduce((o, [key, value]) => {
      //console.log(`reduce key=${key} value=${value}`);
      if (key.match(uuidRex)) {
        const field = key.replace(uuidRex, '');
        if (!o[field]) o[field] = [];
        o[field].push(value);
      } else {
        o[key] = value;
      }
      return o;
    }, {});
}
  
export function getRequestObjectFromForm(formId) {
  const form = document.getElementById(formId);
  const flatObject = Array.from(form.elements)
    .filter((elem) => elem.name && elem.value)
    .map((elem) => [elem.name, elem?.value])
    .filter((elem) => elem && elem[0] && elem[1])
    .reduce((object, [key, value]) => {
      const keys = key.split('.'), last = keys.pop();
      keys.reduce((o, k) => o[k] ??={}, object)[last] = value;
      return object;
    }, {});
  return getNestedObject(flatObject);
}

function getFlatMapFromObject(object) {
  function flat(res, key, val, pre = '') {
    const prefix = [pre, key].filter(v => v).join('.');
    return typeof val === 'object'
      ? Object.keys(val).reduce((prev, curr) => flat(prev, curr, val[curr], prefix), res)
      : Object.assign(res, { [prefix]: val});
  }
  const flatObject = Object.keys(object).reduce((prev, curr) => flat(prev, curr, object[curr]), {});
  return new Map(Object.entries(flatObject));
}

function validateField(key, object, validations) {
  if (key.indexOf('.') === -1) {
    return validations[key](object[key]);
  } else {
    const flatMap = getFlatMapFromObject(object);
    return validations[key](flatMap.get(key));
  }
}

export function validateRequest(request, validations) {
  const errors = Object.keys(validations)
    .map((key) => [key, validateField(key, request, validations)])
    .filter(([_, err]) => err && err.length > 0)
    .reduce((curr, [key, err]) => {
      curr[key] = err; return curr;
    }, {});
  console.log(`validateForm: ${JSON.stringify(errors)}`);
  return errors;
}

export function clearForm(formId) {
  const form = document.getElementById(formId);
  Array.from(form.elements)
    .filter((elem) => elem.type !== 'submit')
    .forEach((elem) => elem.value = null);
}

export function compose(...validations) {
  return (value) => {
    for (let i=0; i < validations.length; i++) {
      let err = validations[i](value);
      if (err) return err;
    }
    return null;
  }
}

export function notNull(errorMsg) {
  return (value) => value ? null : errorMsg;
}

export function notBlank(errorMsg) {
  return (value) => {
    if (value && value.replace(/\s/g, '').length === 0) {
      return errorMsg;
    } else {
      return null;
    }
  }
}

export function validPhoneNumber(errorMsg) {
  return (value) => {
    if (value && !/^\d{10,11}$/.test(value.replace(/[\+()\s-]/g, ''))) {
      return errorMsg;
    } else {
      return null;
    }
  }
}

export function validEmail(errorMsg) {
  return (value) => {
    if (!value || !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      return errorMsg;
    } else {
      return null;
    }
  }
}

export function validLength(min, max, errorMsg) {
  return (value) => {
    if (value) {
      if (min && value.length < min) {
        return errorMsg;
      } else if (max && value.length > max) {
        return errorMsg;
      }
    }
    return null;
  }
}